
  import moment from 'moment'
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        orders: []
      }
    },
    async mounted() {
      try {
        if(this.user){
          const { orders } = await this.$api.getRecentOrders()
          this.orders = orders
        }
      } catch (error) {
        console.error(error)
      }
    },
    computed: {
      ...mapState(['user', 'menu', 'defaultMenu']),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      }
    },
    methods: {
      getImages(order) {
        let images = []
        let menu = this.defaultMenu
        if (this.menu) {
          menu = this.menu
        }
        menu.categories.forEach(category => {
          category.products.forEach(menuItem => {
            order.products.forEach(orderItem => {
              if (menuItem.name == orderItem.name) {
                if (menuItem.images) {
                  images.push('//olo-images-live.imgix.net/'+menuItem.images[1].filename)
                }
              }
            })
          })
        })
        return images
      },
      getDate(order) {
        return moment(order.time.placed, 'YYYYMMDD HH:mm').format('MMM. Do')
      },
      getDescription(order) {
        if (order.products.length) {
          let desc = order.products[0].name
          order.products.forEach((product, i) => {
            if (i > 0) {
              desc = desc+', '+product.name
            }
          })
          if (desc.length > 50) {
            desc = desc.substring(0,51)+'...'
          }
          return desc
        }
      },
      async reorder(order) {
        try {
          await this.$api.createReorder(order.id)
          this.$router.push('/order/checkout/')
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
